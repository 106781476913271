<template>
    <!-- banner管理组件 -->
    <div>
        <div class="top">
            <div class="top_left">
                <a-button icon="plus" type="primary" @click="addBanner" v-show="isShowAdd">新建banner</a-button>
            </div>
            <div class="top_rigiht">
                <span>banner状态</span>
                <a-select v-model="putawayFlag" style="width: 120px" :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body" @change="changePutawayFlag">
                    <a-select-option v-for="item in putawayFlagArr" :key="item.key">{{ item.value }}</a-select-option>
                </a-select>
                <a-input ref="userNameInput" v-model="searchVal" placeholder="请输入banner名称" style="width: 200px" @pressEnter="searchClick">
                    <a-icon slot="prefix" type="search"/>
                </a-input>
                <a-button icon="search" type="primary" @click="searchClick" :disabled="isDisableSearch">搜索</a-button>
            </div>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :customRow="customRow" :loading="tableLoading">
            <span slot="number" slot-scope="number, record, index">
                <span>{{ index + 1 }}</span>
            </span>
            <img v-viewer class="avatar" slot="imageUrl" slot-scope="imageUrl, record" :src="record.imageUrl" alt="头像"/>
            <span slot="putawayFlagText" slot-scope="putawayFlagText, record"><a-badge v-show="record.putawayFlag == 0" status="error" text="已下架"/>
                <a-badge v-show="record.putawayFlag == 1" status="processing" text="定时上架"/>
                <a-badge v-show="record.putawayFlag == 2" status="success" text="已上架"/>
                <a-badge v-show="record.putawayFlag == 3" status="warning" text="暂不上架"/>
            </span>
            <span slot="operation" slot-scope="operation, record">
                <a v-show="isShowUpd" class="margin_right20" @click="editClick(record)">编辑</a>
                <a v-show="record.putawayFlag == 2 && isShowOut" class="margin_right20" @click="TapeOutClick(record)">下架</a>
                <a v-show="record.putawayFlag != 2 && isShowOnline" class="margin_right20" @click="TapeOutClick(record)">上架</a>
                <a v-show="isShowDel" @click="delClick(record)">删除</a>
            </span>
        </a-table>

        <!-- 分页功能 -->
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {
    BannerShowBannersApi,
    BannerDeleteApi,
    BannerSortOperationApi,
    BannerOnlinetApi,
    BannerOutApi,
} from "@/request/api/indexManage";
import {Modal, message} from "ant-design-vue";
import {codeFn} from "@/utils/tools";

export default {
    created() {
        let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
        dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
        this.BannerShowBannersFn();
        if (codeFn("/admin/home/banner/saveBanner")) this.isShowAdd = true;
        if (codeFn("/admin/home/banner/updBanner")) this.isShowUpd = true;
        if (codeFn("/admin/home/banner/onLine")) this.isShowOnline = true;
        if (codeFn("/admin/home/banner/out")) this.isShowOut = true;
        if (codeFn("/admin/home/banner/delete")) this.isShowDel = true;
    },
    watch: {
        searchVal(newVal) {
            if (newVal.length <= 0) this.BannerShowBannersFn();
        },
    },
    components: {MyPagination},
    data() {
        return {
            pageNo: 1,
            pageSize: 20,
            searchVal: "", // 输入框的值
            putawayFlag: "", // banner状态的默认值
            putawayFlagArr: [{key: "", value: "全部状态"}], // 课程状态数组
            isDisableSearch: false, // 搜索按钮是否禁用
            count: 0, // 列表的总条数
            tableLoading: true, // 表格loading图
            columns: [
                {
                    title: "序号",
                    dataIndex: "number",
                    key: "number",
                    scopedSlots: {customRender: "number"},
                },
                {
                    title: "banner图片",
                    dataIndex: "imageUrl",
                    key: "imageUrl",
                    width: "20%",
                    scopedSlots: {customRender: "imageUrl"},
                },
                {
                    title: "名称",
                    dataIndex: "name",
                    key: "name",
                    scopedSlots: {customRender: "name"},
                },
                {
                    title: "状态",
                    dataIndex: "putawayFlagText",
                    key: "putawayFlagText",
                    scopedSlots: {customRender: "putawayFlagText"},
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    sorter: true,
                    key: "createTime",
                    sorter: (a, b) => {
                        let aTime = new Date(a.createTime).getTime();
                        let bTime = new Date(b.createTime).getTime();
                        return aTime - bTime;
                    },
                },
                {
                    title: "操作",
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: {customRender: "operation"},
                },
            ],
            tableData: [],
            sourceObj: {}, // 表格源对象
            sourceIndex: null, // 表格源对象索引
            targetObj: {}, // 表格目标对象
            targetIndex: null, // 表格目标对象索引
            isShowAdd: false, // 新增是否隐藏
            isShowUpd: false, // 修改是否隐藏
            isShowOnline: false, // 上架是否隐藏
            isShowOut: false, // 上架是否隐藏
            isShowDel: false, // 删除是否隐藏
        };
    },
    methods: {
        // 新建banner按钮
        addBanner() {
            this.$router.push(`/indexManage/indexConfig/addBanner/0`);
        },
        // 课程状态改变的回调
        changePutawayFlag(value) {
            this.putawayFlag = value;
            this.BannerShowBannersFn();
        },
        // 回车和搜索的按钮
        searchClick() {
            this.isDisableSearch = true;
            setTimeout(() => {
                this.isDisableSearch = false;
            }, 3000);
            this.pageNo = 1;
            this.BannerShowBannersFn();
        },
        // 编辑的按钮
        editClick(record) {
            this.$router.push(`/indexManage/indexConfig/addBanner/${record.key}`);
        },
        // 上下架的按钮
        TapeOutClick(record) {
            if (record.putawayFlag == 2) {
                Modal.confirm({
                    title: "提示",
                    content: "下线后该banner图不会在APP首页展示,是否确认下线?",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    width: "500px",
                    onOk() {
                        BannerOutApi({id: record.key}).then(({code}) => {
                            if (code == 200) {
                                message.success("下架成功", 1);
                                record.putawayFlag = 0;
                            }
                        });
                    },
                });
            } else {
                BannerOnlinetApi({id: record.key}).then(({code}) => {
                    if (code == 200) {
                        message.success("上架成功", 1);
                        record.putawayFlag = 2;
                    }
                });
            }
        },
        // 删除的按钮
        delClick(record) {
            let that = this;
            if (record.putawayFlag == 2) {
                message.error("请先下线banner图后再删除");
            } else {
                Modal.confirm({
                    title: "提示",
                    content: "是否确认删除banner图",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        BannerDeleteApi({id: record.key}).then(({code}) => {
                            if (code == 200) {
                                message.success("删除成功", 3);
                                let tableData = that.tableData;
                                that.tableData = tableData.filter(
                                    (item) => item.key !== record.key
                                );
                            }
                        });
                    },
                });
            }
        },
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.BannerShowBannersFn();
        },
        // 表格拖动
        customRow(record, index) {
            return {
                props: {
                    // draggable: 'true'
                },
                style: {
                    cursor: "pointer",
                },
                on: {
                    // 鼠标移入
                    mouseenter: (event) => {
                        var ev = event || window.event; // 兼容IE
                        ev.target.draggable = true;
                    }, // 开始拖拽
                    dragstart: (event) => {
                        var ev = event || window.event; // 阻止冒泡    // 兼容IE
                        ev.stopPropagation(); // 得到源目标数据
                        this.sourceObj = record;
                        this.sourceIndex = index;
                    }, // 拖动元素经过的元素
                    dragover: (event) => {
                        var ev = event || window.event; // 阻止默认行为     // 兼容 IE
                        ev.preventDefault();
                    }, // 鼠标松开
                    drop: (event) => {
                        // 兼容IE
                        var ev = event || window.event; // 阻止冒泡
                        ev.stopPropagation(); // 得到目标数据
                        this.targetObj = record;
                        this.targetIndex = index;
                        this.tableData.splice(this.sourceIndex, 1);
                        this.tableData.splice(this.targetIndex, 0, this.sourceObj);
                        let arr = [];
                        this.tableData.map((item, index) => {
                            arr.push({id: item.key, sort: index});
                        });
                        BannerSortOperationApi(arr).then(({code, data}) => {
                            if (code == 200) {
                                message.success("排序成功", 1);
                            }
                        });
                    },
                },
            };
        },
        //封装banner列表函数
        BannerShowBannersFn() {
            BannerShowBannersApi({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                putawayFlag: this.putawayFlag, //上架状态：[0：已下架 1：定时上架 2：已上架[立即上架] 3：暂不上架]
                name: this.searchVal, //banner名称
            }).then(({code, data}) => {
                if (code == 200) {
                    this.tableLoading = false;
                    let newData = data.data.map((item) => {
                        return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
                    });
                    this.tableData = newData;
                    this.count = data.count;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: space-between;

    .top_rigiht {
        span {
            margin-right: 10px;
        }
    }

    .ant-select {
        margin-right: 10px;
    }
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

/deep/ .ant-table-wrapper {
    margin-top: 20px;
}

/deep/ .ant-table-thead > tr > th {
    font-weight: 600;
}
</style>
