<template>
  <!-- 首页配置页面 -->
  <div class="padding24 color_white" id="index_config">
    <div v-show="$route.meta.isShow">
      <a-tabs :activeKey="activeKey" @change="tabsChange">
        <a-tab-pane key="0" tab="banner管理" v-if="isShowBanners">
          <BannerManage />
        </a-tab-pane>
        <a-tab-pane key="1" tab="精品馆" v-if="isShowHotLessons">
          <HotLessons :activeKey="activeKey" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="读书会员" v-if="isShowbBoutique">
          <BoutiqueCourse />
        </a-tab-pane>
        <a-tab-pane key="3" tab="每日申识" v-if="isShowbTheDaily">
          <TheDaily />
        </a-tab-pane>
      </a-tabs>
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import BannerManage from "./modules/BannerManage";
import HotLessons from "./modules/HotLessons";
import BoutiqueCourse from "./modules/BoutiqueCourse";
import TheDaily from "./modules/TheDaily";
import { codeFn } from "@/utils/tools";
export default {
  components: { BannerManage, HotLessons, BoutiqueCourse, TheDaily },
  created() {
    const tab = localStorage.getItem("activeKey");
    if (tab) {
      this.activeKey = tab;
      switch (tab) {
        case "0":
          this.tabIndex = 0;
          break;
        case "1":
          this.tabIndex = 1;
          break;
        case "2":
          this.tabIndex = 2;
          break;
        case "3":
          this.tabIndex = 3;
          break;
        default:
          this.tabIndex = 0;
          break;
      }
    }
    if (codeFn("/admin/home/banner/showBanners")) this.isShowBanners = true;
    if (codeFn("/admin/course/boutique/pageCourseBoutiqueResult")) {
      this.isShowHotLessons = true;
    }
    if (codeFn("/admin/course/hot/pageAdminCourseHotResult")) {
      this.isShowbBoutique = true;
    }
    if (codeFn("/admin/home/recommend/list")) {
      this.isShowbTheDaily = true;
    }
    // if (this.isShowBanners) {
    //   this.activeKey = "0";
    // } else if (this.isShowHotLessons) {
    //   this.activeKey = "1";
    // } else if (this.isShowbBoutique) {
    //   this.activeKey = "2";
    // }
  },
  data() {
    return {
      activeKey: "0", // 导航栏默认选中的key
      isShowBanners: false, // banner是否显示
      isShowHotLessons: false, // 热门好课是否显示
      isShowbBoutique: false, // 精品小课是否显示
      isShowbTheDaily: false, // 每日申识是否显示
    };
  },
  methods: {
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#index_config");
    },
    // 导航栏切换的回调
    tabsChange(activeKey) {
      this.activeKey = activeKey;
      localStorage.setItem("activeKey", activeKey);
    },
  },
};
</script>
 
<style lang="less" scoped>
#index_config {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
</style>