<template>
  <!-- 每日申识 -->
  <div>
    <div class="top">
      <div class="top_left">
        <a-button icon="plus" type="primary" @click="addClick" v-if="isShowAdd">
          添加申识
        </a-button>
      </div>
      <div class="top_rigiht">
        <span>申识状态</span>
        <a-select
          v-model="form.putawayFlag"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="changePutawayFlag"
        >
          <a-select-option v-for="item in putawayFlagArr" :key="item.key">
            {{ item.value }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="form.title"
          placeholder="请输入申识标题"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :customRow="customRow"
      :loading="tableLoading"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
        <a-badge
          v-show="record.putawayFlag == 0"
          status="error"
          text="已下线"
        />
        <a-badge
          v-show="record.putawayFlag == 1"
          status="processing"
          text="定时上架"
        />
        <a-badge
          v-show="record.putawayFlag == 2"
          status="success"
          text="已上线"
        />
        <a-badge
          v-show="record.putawayFlag == 3"
          status="warning"
          text="暂不上架"
        />
      </span>
      <span slot="coursePutawayFlag" slot-scope="coursePutawayFlag, record">
        <a-badge
          v-if="
            record.coursePutawayFlag == 0 &&
            record.businessType == 1 &&
            record.businessName != '——'
          "
          status="error"
          text="已下架"
        />
        <span v-if="record.businessName == '——'"> —— </span>
        <a-badge
          v-if="record.coursePutawayFlag == 1 && record.businessType == 1"
          status="processing"
          text="定时上架"
        />
        <a-badge
          v-if="record.coursePutawayFlag == 2 && record.businessType == 1"
          status="success"
          text="已上架"
        />
        <a-badge
          v-if="record.coursePutawayFlag == 3 && record.businessType == 1"
          status="warning"
          text="暂不上架"
        />
        <a-badge
          v-if="record.memberStatus == 1 && record.businessType == 2"
          status="success"
          text="使用中"
        />
        <a-badge
          v-if="record.memberStatus == 2 && record.businessType == 2"
          status="error"
          text="禁用中"
        />
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a v-if="isShowUpd" class="margin_right20" @click="editClick(record)">
          编辑
        </a>
        <a
          v-show="record.putawayFlag == 2 && isShowOut"
          class="margin_right20"
          @click="tapeOutClick(record)"
          >下线
        </a>
        <a
          v-show="record.putawayFlag != 2 && isShowOnline"
          class="margin_right20"
          @click="tapeOutClick(record)"
          >上线
        </a>
        <a v-if="isShowDel" @click="delClick(record)">删除</a>
      </span>
    </a-table>
    <MyPagination
      :count="count"
      :pageNo="form.pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination.vue";
import {
  RecommendListApi,
  RecommendOutApi,
  RecommendOnlineApi,
  RecommendDelApi,
  RecommendSortApi,
} from "@/request/api/indexManage";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
    this.RecommendListFn();
    if (codeFn("/admin/home/recommend/add")) this.isShowAdd = true;
    if (codeFn("/admin/home/recommend/upd")) this.isShowUpd = true;
    if (codeFn("/admin/home/recommend/onLine/{id}")) this.isShowOnline = true;
    if (codeFn("/admin/home/recommend/out/{id}")) this.isShowOut = true;
    if (codeFn("/admin/home/recommend/delete/{id}")) this.isShowDel = true;
  },
  data() {
    return {
      count: 0,
      form: {
        pageNo: 1,
        pageSize: 20,
        putawayFlag: "",
        title: "",
      },
      putawayFlagArr: [{ key: "", value: "全部状态" }], // 课程状态数组
      tableLoading: true,
      columns: [
        {
          title: "序号",
          dataIndex: "number",
          key: "number",
          width: "5%",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "申识标题",
          dataIndex: "title",
          key: "title",
          width: "30%",
        },
        {
          title: "申识状态",
          dataIndex: "putawayFlagText",
          key: "putawayFlagText",
          width: "7%",
          scopedSlots: { customRender: "putawayFlagText" },
        },
        {
          title: "关联商品",
          dataIndex: "businessName",
          key: "businessName",
          width: "25%",
        },
        {
          title: "商品状态",
          dataIndex: "coursePutawayFlag",
          key: "coursePutawayFlag",
          scopedSlots: { customRender: "coursePutawayFlag" },
        },
        {
          title: "更新时间",
          dataIndex: "updateTime",
          sorter: true,
          key: "updateTime",
          width: "12%",
          sorter: (a, b) => {
            let aTime = new Date(a.updateTime).getTime();
            let bTime = new Date(b.updateTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isDisableSearch: false, // 搜索按钮是否禁用
      isShowAdd: false,
      isShowUpd: false,
      isShowOnline: false,
      isShowOut: false,
      isShowDel: false,
    };
  },
  methods: {
    addClick() {
      this.$router.push(`/indexManage/indexConfig/addTheDaily/0`);
    },
    editClick(record) {
      this.$router.push(`/indexManage/indexConfig/addTheDaily/${record.id}`);
    },
    // 申识状态改变的回调
    changePutawayFlag(value) {
      this.form.putawayFlag = value;
      this.RecommendListFn();
    },
    // 上下线
    tapeOutClick(record) {
      if (record.putawayFlag == 2) {
        Modal.confirm({
          title: "提示",
          content: "下线后该申识不会在APP首页展示，是否确认下架？",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk() {
            RecommendOutApi({ id: record.id }).then(({ code }) => {
              if (code == 200) {
                message.success("下架成功", 1);
                record.putawayFlag = 0;
              }
            });
          },
        });
      } else {
        RecommendOnlineApi({ id: record.id }).then(({ code }) => {
          if (code == 200) {
            message.success("上架成功", 1);
            record.putawayFlag = 2;
          }
        });
      }
    },
    // 删除
    delClick(record) {
      let that = this;
      if (record.putawayFlag == 2) {
        message.error("请先下线每日申识后再删除");
      } else {
        Modal.confirm({
          title: "提示",
          content: "删除后相关数据无法恢复，是否确认删除？",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            RecommendDelApi({ id: record.id }).then(({ code }) => {
              if (code == 200) {
                message.success("删除成功", 3);
                let tableData = that.tableData;
                that.tableData = tableData.filter(
                  (item) => item.id !== record.id
                );
              }
            });
          },
        });
      }
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.form.pageNo = current;
      this.form.pageSize = pageSize;
      this.RecommendListFn();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.pageNo = 1;
      this.RecommendListFn();
    },
    // 表格拖动
    customRow(record, index) {
      return {
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            var ev = event || window.event; // 兼容IE
            ev.target.draggable = true;
          }, // 开始拖拽
          dragstart: (event) => {
            var ev = event || window.event; // 阻止冒泡    // 兼容IE
            ev.stopPropagation(); // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          }, // 拖动元素经过的元素
          dragover: (event) => {
            var ev = event || window.event; // 阻止默认行为     // 兼容 IE
            ev.preventDefault();
          }, // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event; // 阻止冒泡
            ev.stopPropagation(); // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            this.tableData.splice(this.sourceIndex, 1);
            this.tableData.splice(this.targetIndex, 0, this.sourceObj);
            let arr = [];
            this.tableData.map((item, index) => {
              arr.push({ id: item.id, sort: index });
            });
            RecommendSortApi(arr).then(({ code, data }) => {
              if (code == 200) {
                message.success("排序成功", 1);
              }
            });
          },
        },
      };
    },
    // 申识列表
    RecommendListFn() {
      this.tableLoading = true;
      RecommendListApi(this.form).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item, index) => {
            item.key = index;
            item.businessName =
              item.businessName == "" ? "——" : item.businessName;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
  .ant-select {
    margin-right: 10px;
  }
}
</style>