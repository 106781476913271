<template>
  <!-- 精品馆组件 -->
  <div>
    <div class="top">
      <div class="top_left">
        <a-button
          icon="plus"
          type="primary"
          @click="addCourse"
          v-show="isShowAdd"
        >
          添加课程
        </a-button>
      </div>
      <div class="top_rigiht">
        <span>课程状态</span>
        <a-select
          v-model="putawayFlag"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="changePutawayFlag"
        >
          <a-select-option v-for="item in putawayFlagArr" :key="item.key">
            {{ item.value }}
          </a-select-option>
        </a-select>
        <a-input
          v-model="searchVal"
          placeholder="请输入课程名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :customRow="customRow"
      :loading="tableLoading"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      <img
        v-viewer
        class="avatar"
        slot="promotionUrl"
        slot-scope="promotionUrl, record"
        :src="record.promotionUrl"
        alt="推广图"
      />
      <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
        <a-badge
          v-show="record.putawayFlag == 0"
          status="error"
          :text="record.putawayFlagText"
        />
        <a-badge
          v-show="record.putawayFlag == 1"
          status="processing"
          :text="record.putawayFlagText"
        />
        <a-badge
          v-show="record.putawayFlag == 2"
          status="success"
          :text="record.putawayFlagText"
        />
        <a-badge
          v-show="record.putawayFlag == 3"
          status="warning"
          :text="record.putawayFlagText"
        />
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a v-show="isShowUpd" class="margin_right20" @click="editClick(record)"
          >编辑</a
        >
        <a v-show="isShowDel" @click="delClick(record)">移出</a>
      </span>
    </a-table>

    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {
  PageCourseBoutiqueResultApi,
  DelCourseBoutiqueApi,
  SortCourseBoutiqueApi,
} from "@/request/api/indexManage";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  props: ["activeKey"],
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
    this.PageCourseBoutiqueResultFn();
    if (codeFn("/admin/course/boutique/saveCourseBoutique")) {
      this.isShowAdd = true;
    }
    if (codeFn("/admin/course/boutique/updCourseBoutique")) {
      this.isShowUpd = true;
    }
    if (codeFn("/admin/course/boutique/delCourseBoutique")) {
      this.isShowDel = true;
    }
  },
  watch: {
    searchVal(newVal) {
      if (newVal.length <= 0) {
        this.PageCourseBoutiqueResultFn();
      }
    },
    activeKey(newVal) {
      if (newVal == "1") {
        this.PageCourseBoutiqueResultFn();
      }
    },
  },
  components: { MyPagination },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      searchVal: "", // 输入框的值
      putawayFlag: "", // banner状态的默认值
      putawayFlagArr: [{ key: "", value: "全部状态" }], // 课程状态数组
      isDisableSearch: false, // 搜索按钮是否禁用
      count: 0, // 列表的总条数
      tableLoading: true, // 表格loading图
      columns: [
        {
          title: "序号",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "推广图",
          dataIndex: "promotionUrl",
          key: "promotionUrl",
          width: "10%",
          scopedSlots: { customRender: "promotionUrl" },
        },
        {
          title: "课程名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "课程状态",
          dataIndex: "putawayFlagText",
          key: "putawayFlagText",
          scopedSlots: { customRender: "putawayFlagText" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      sourceObj: {}, // 表格源对象
      sourceIndex: null, // 表格源对象索引
      targetObj: {}, // 表格目标对象
      targetIndex: null, // 表格目标对象索引
      isShowAdd: false, // 新增是否隐藏
      isShowUpd: false, // 修改是否隐藏
      isShowDel: false, // 删除是否隐藏
    };
  },
  methods: {
    // 新建课程按钮
    addCourse() {
      this.$router.push(`/indexManage/indexConfig/addHotLessons/0`);
    },
    // 课程状态改变的回调
    changePutawayFlag(value) {
      this.putawayFlag = value;
      this.PageCourseBoutiqueResultFn();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.pageNo = 1;
      this.PageCourseBoutiqueResultFn();
    },
    // 编辑的按钮
    editClick(record) {
      location.href = `/indexManage/indexConfig/addHotLessons/${record.key}`;
    },
    // 删除的按钮
    delClick(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "移出后课程不会显示到精品馆,是否确认移出",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          DelCourseBoutiqueApi({ id: record.key }).then(({ code }) => {
            if (code == 200) {
              message.success("移出成功", 3);
              let tableData = that.tableData;
              that.tableData = tableData.filter(
                (item) => item.key !== record.key
              );
            }
          });
        },
      });
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.PageCourseBoutiqueResultFn();
    },
    // 表格拖动
    customRow(record, index) {
      return {
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            var ev = event || window.event; // 兼容IE
            ev.target.draggable = true;
          }, // 开始拖拽
          dragstart: (event) => {
            var ev = event || window.event; // 阻止冒泡    // 兼容IE
            ev.stopPropagation(); // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          }, // 拖动元素经过的元素
          dragover: (event) => {
            var ev = event || window.event; // 阻止默认行为     // 兼容 IE
            ev.preventDefault();
          }, // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event; // 阻止冒泡
            ev.stopPropagation(); // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            this.tableData.splice(this.sourceIndex, 1);
            this.tableData.splice(this.targetIndex, 0, this.sourceObj);
            let arr = [];
            this.tableData.map((item, index) => {
              arr.push({ id: item.key, sort: index });
            });
            SortCourseBoutiqueApi(arr).then(({ code, data }) => {
              if (code == 200) {
                message.success("排序成功", 1);
              }
            });
          },
        },
      };
    },
    // 封装banner列表函数
    PageCourseBoutiqueResultFn() {
      PageCourseBoutiqueResultApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        putawayFlag: this.putawayFlag, // 上架状态：[0：已下架 1：定时上架 2：已上架[立即上架] 3：暂不上架]
        name: this.searchVal, // banner名称
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.tableData = newData;
          this.count = data.count;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
  .ant-select {
    margin-right: 10px;
  }
}
.avatar {
  width: 160px;
  height: 80px;
  object-fit: contain;
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>