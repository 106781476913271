import { render, staticRenderFns } from "./IndexConfig.vue?vue&type=template&id=7aa78bb2&scoped=true&"
import script from "./IndexConfig.vue?vue&type=script&lang=js&"
export * from "./IndexConfig.vue?vue&type=script&lang=js&"
import style0 from "./IndexConfig.vue?vue&type=style&index=0&id=7aa78bb2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa78bb2",
  null
  
)

export default component.exports